//统一管理API
import requests from "./request";
import union from "xe-utils";

/*登录请求*/
export const Login = (code) => requests({
    url: '/login',
    method: 'get',
    params: {
        code: code
    }
})

/*认证*/
export const Authentication = (obj) => requests({
    url: '/authentication',
    method: 'get',
    params: {
        newUnionid: obj.newUnionid,
        name: obj.name,
        telephone: obj.telephone
    }
})

/*获取姓名*/
export const GetEmployeeName = (unionid) => requests({
    url: '/getEmployeeName',
    method: 'get',
    params: {
        unionid: unionid
    }
})

/*根据 projName 获取 房源*/
export const GetHouseInfosByProjName = (projName) => requests({
    url: '/getHouseInfosByProjName',
    method: 'get',
    params: {
        projName: projName
    }
})

/*获取用户*/
export const GetEmployees = (department, unionid) => requests({
    url: '/employeeList',
    method: 'get',
    params: {
        department: department,
        unionid: unionid
    }
})

/*权限*/
export const GetAuthority = (authorityName, unionid) => requests({
    url: '/getAuthority',
    method: 'get',
    params: {
        authorityName: authorityName,
        unionid: unionid
    }
})

/* 获取商机权限 */
export const GetBusinessAuthority = (unionid) => requests({
    url: '/getBusinessAuthority',
    method: 'get',
    params: {
        unionid: unionid
    }
})

/*操作记录*/
export const InsertOperationRecord = (unionid, operationType, detail) => requests({
    url: '/insertOperationRecord',
    method: 'post',
    params: {
        unionid: unionid,
        operationType: operationType,
        detail: detail
    }
})

/*查询 operationRecords*/
export const GetOperationRecords = (operationType) => requests({
    url: '/getOperationRecords',
    method: 'get',
    params: {
        operationType: operationType
    }
})

//项目(包含全部选项)
export const GetProjects = () => requests({
    url: '/projectList',
    method: 'get'
})

//项目（不包含全部选项）
export const GetProjectsWithoutAll = () => requests({
    url: '/getProjectListWithoutAll',
    method: 'get'
})

/* 根据缩写获取项目 */
export const GetProjectByAbbr = (abbreviation) => requests({
    url: '/getProjectByAbbr',
    method: 'get',
    params: {
        abbreviation: abbreviation
    }
})

//房源列表
export const GetHouseInfos = (obj) => requests({
    url: '/getHouseInfos',
    method: 'get',
    params: {
        projName: obj.projName,
        houseName: obj.houseName,
        clientName: obj.clientName
    }
})

//房源状态列表
export const GetHouseStates = () => requests({
    url: '/getHouseStates',
    method: 'get'
})

//房源详情
export const GetHouseInfoDetail = (houseId) => requests({
    url: '/getHouseInfoDetail',
    method: 'get',
    params: {
        houseId: houseId
    }
})

//房源-当前客户
export const GetNowClient = (houseId) => requests({
    url: '/getNowClient',
    method: 'get',
    params: {
        houseId: houseId
    }
})

//房源-未来客户
export const GetFutureClient = (houseId) => requests({
    url: '/getFutureClient',
    method: 'get',
    params: {
        houseId: houseId
    }
})

//房源-历史客户
export const GetHistoryClient = (houseId) => requests({
    url: '/getHistoryClient',
    method: 'get',
    params: {
        houseId: houseId
    }
})

//房源-价格信息
export const GetHousePrice = (houseId) => requests({
    url: '/getHousePrice',
    method: 'get',
    params: {
        houseId: houseId
    }
})

/*修改价格信息*/
export const UpdateHousePrice = (houseId, unitPrice) => requests({
    url: '/updateHousePrice',
    method: 'post',
    params: {
        houseId: houseId,
        unitPrice: unitPrice
    }
})

/*房源过户情况列表*/
export const GetTransferSituation = (obj) => requests({
    url: '/getTransferSituation',
    method: 'get',
    params: {
        projName: obj.projName,
        houseName: obj.houseName,
        clientName: obj.clientName
    }
})

/*房源过户情况*/
export const GetHouseTransferSituation = (houseId) => requests({
    url: '/getHouseTransferSituation',
    method: 'get',
    params: {
        houseId: houseId
    }
})

/*修改过户情况*/
export const UpdateTransferSituation = (houseId, houseTransfer, electricMeterTransfer) => requests({
    url: '/updateTransferSituation',
    method: 'post',
    params: {
        houseId: houseId,
        houseTransfer: houseTransfer,
        electricMeterTransfer: electricMeterTransfer
    }
})

/*房源客户列表*/
export const GetHouseClients = (obj) => requests({
    url: '/getHouseClients',
    method: 'get',
    params: {
        abbreviation: obj.projName,
        clientName: obj.clientName
    }
})

/*客户-当前房源*/
export const GetNowHouses = (clientId) => requests({
    url: "/getNowHouses",
    method: 'get',
    params: {
        clientId: clientId
    }
})

/*客户-未来房源*/
export const GetFutureHouses = (clientId) => requests({
    url: "/getFutureHouses",
    method: 'get',
    params: {
        clientId: clientId
    }
})

/*客户-历史房源*/
export const GetHistoryHouses = (clientId) => requests({
    url: "/getHistoryHouses",
    method: 'get',
    params: {
        clientId: clientId
    }
})

/*更新 clientHouseInfo*/
export const UpdateClientHouseInfo = (obj) => requests({
    url: "/updateClientHouseInfo",
    method: 'post',
    params: {
        obj: obj
    }
})

/*获取client对象*/
export const GetHouseClient = (clientId) => requests({
    url: '/getHouseClient',
    method: 'get',
    params: {
        clientId: clientId
    }
})

/*根据时间段获取空房源*/
export const GetVacantHousesByTime = (projName, startDate, endDate) => requests({
    url: '/getVacantHousesByTime',
    method: 'get',
    params: {
        projName: projName,
        startDate: startDate,
        endDate: endDate
    }
})

/*新增 clientHouseInfo*/
export const InsertClientHouseInfos = (obj) => requests({
    url: '/insertClientHouseInfos',
    method: 'post',
    params: {
        clientId: obj.clientId,
        clientName: obj.clientName,
        clientAttribute: obj.clientAttribute,
        houseState: obj.houseState,
        contractId: obj.contractId,
        contractStartDate: obj.contractStartDate,
        contractEndDate: obj.contractEndDate,
        actualStartDate: obj.actualStartDate,
        actualEndDate: obj.actualEndDate,
        wyfGgnhfBillingCycle: obj.wyfGgnhfBillingCycle,
        wyfStartDate: obj.wyfStartDate,
        wyfEndDate: obj.wyfEndDate,
        ggnhfStartDate: obj.ggnhfStartDate,
        ggnhfEndDate: obj.ggnhfEndDate,
        houseIds: obj.houseId.join(',')
    }
})

/*其他客户列表*/
export const GetOtherClients = (obj) => requests({
    url: '/getOtherClients',
    method: 'get',
    params: {
        abbreviation: obj.projName,
        clientName: obj.clientName
    }
})

/*根据 clientId 获取 其他客户*/
export const GetOtherClient = (clientId) => requests({
    url: '/getOtherClient',
    method: 'get',
    params: {
        clientId: clientId
    }
})

/*更新 otherClient 信息*/
export const UpdateOtherClient = (clientId, obj) => requests({
    url: '/updateOtherClient',
    method: 'post',
    params: {
        clientId: clientId,
        actualStartDate: obj.actualStartDate,
        actualEndDate: obj.actualEndDate,
        remark: obj.remark
    }
})

/*获取电表列表*/
export const GetElecMeters = (obj) => requests({
    url: '/getElecMeters',
    method: 'get',
    params: {
        abbreviation: obj.projName,
        meterId: obj.elecMeterId,
        meterName: obj.elecMeterName
    }
})

/*根据 meterId 获取 电表对象*/
export const GetElecMeterDetail = (meterId) => requests({
    url: '/getElecMeterDetail',
    method: 'get',
    params: {
        meterId: meterId
    }
})

/*更新电表信息*/
export const UpdateElecMeterDetail = (meterId, meterLoad, removeInfo) => requests({
    url: '/updateElecMeterDetail',
    method: 'post',
    params: {
        meterId: meterId,
        meterLoad: meterLoad,
        removeInfo: removeInfo
    }
})

/*能耗基础信息*/
export const GetElecRecords = (obj) => requests({
    url: '/getElecRecords',
    method: 'get',
    params: {
        abbreviation: obj.projName,
        month: obj.month,
        meterId: obj.meterId,
        meterName: obj.meterName
    }
})

/*根据 elecId 获取 elecRecord*/
export const GetElecRecord = (elecId) => requests({
    url: '/getElecRecord',
    method: 'get',
    params: {
        elecId: elecId
    }
})

/*总分表能耗数据*/
export const GetSumMeterRecords = (obj) => requests({
    url: '/getSumMeterRecords',
    method: 'get',
    params: {
        abbreviation: obj.projName,
        month: obj.month,
        meterId: obj.meterId,
        meterName: obj.meterName
    }
})

/*总分表损耗*/
export const GetSumMeterRecord = (meterId, month) => requests({
    url: '/getSumMeterRecord',
    method: 'get',
    params: {
        meterId: meterId,
        month: month
    }
})

/*总分表 - 子表*/
export const GetSumMeterChildMeterRecords = (meterId, month) => requests({
    url: '/getSumMeterChildMeterRecords',
    method: 'get',
    params: {
        meterId: meterId,
        month: month
    }
})

/*公摊表能耗数据*/
export const GetSharedMeterRecords = (obj) => requests({
    url: '/getSharedMeterRecords',
    method: 'get',
    params: {
        abbreviation: obj.projName,
        month: obj.month,
        meterId: obj.meterId,
        meterName: obj.meterName
    }
})

/*根据 elecId 获取 ElecRecord_SharedMeter*/
export const GetSharedMeterRecord = (elecId) => requests({
    url: '/getSharedMeterRecord',
    method: 'get',
    params: {
        elecId: elecId
    }
})

/*自用表能耗数据*/
export const GetSelfUseMeterRecords = (obj) => requests({
    url: '/getSelfUseMeterRecords',
    method: 'get',
    params: {
        abbreviation: obj.projName,
        month: obj.month,
        meterId: obj.meterId,
        meterName: obj.meterName
    }
})

/*根据 elecId 获取 ElecRecord_SelfUseMeter*/
export const GetSelfUseMeterRecord = (elecId) => requests({
    url: '/getSelfUseMeterRecord',
    method: 'get',
    params: {
        elecId: elecId
    }
})

/*更新 elecRecord 备注*/
export const UpdateElecRecordRemark = (obj) => requests({
    url: '/updateElecRecordRemark',
    method: 'post',
    params: {
        elecId: obj.elecId,
        remark: obj.remark
    }
})

/*更新电表负载*/
export const UpdateElecMeterLoad = (obj) => requests({
    url: '/updateElecMeterLoad',
    method: 'post',
    params: {
        meterId: obj.meterId,
        meterLoad: obj.meterLoad
    }
})

/*获取 elecReportKinds*/
export const GetElecReportKinds = (obj) => requests({
    url: '/getElecReportKinds',
    method: 'get',
    params: {
        abbreviation: obj.projName,
        month: obj.month
    }
})

/*获取 elecReport_01*/
export const GetElecReport_01 = (obj) => requests({
    url: '/getElecReport01',
    method: 'get',
    params: {
        abbreviation: obj.projName,
        month: obj.month
    }
})

/*获取 elecReport_02*/
export const GetElecReport_02 = (obj) => requests({
    url: '/getElecReport02',
    method: 'get',
    params: {
        abbreviation: obj.projName,
        month: obj.month
    }
})

/*获取 elecReport_03*/
export const GetElecReport_03 = (obj) => requests({
    url: '/getElecReport03',
    method: 'get',
    params: {
        abbreviation: obj.projName,
        month: obj.month
    }
})

/*获取 elecReport_04*/
export const GetElecReport_04 = (obj) => requests({
    url: '/getElecReport04',
    method: 'get',
    params: {
        abbreviation: obj.projName,
        month: obj.month
    }
})

/*获取 elecReport_05*/
export const GetElecReport_05 = (obj) => requests({
    url: '/getElecReport05',
    method: 'get',
    params: {
        abbreviation: obj.projName,
        month: obj.month,
        unitPrice: obj.unitPrice
    }
})

/* 获取内部用电报表 */
export const GetInternalElecReport = (projName,month) => requests({
    url: '/getInternalElecReport',
    method: 'get',
    params: {
        projName: projName,
        month: month
    }
})

/* 审核内部用电报表 */
export const CheckInternalElecReport = (obj,unionid) => requests({
    url: '/checkInternalElecReport',
    method:'post',
    params: {
        projName: obj.projName,
        clientId: obj.clientId,
        clientName: obj.clientName,
        billMonth: obj.billMonth,
        billId: obj.id,
        unionid: unionid
    }
})

/*获取 elecReport 审核人姓名*/
export const GetElecReportReviewer = (obj) => requests({
    url: '/getElecReportReviewer',
    method: 'get',
    params: {
        kind: obj.kind,
        abbreviation: obj.projName,
        month: obj.month
    }
})

/*获取 供电局用量是否已存在*/
export const GetOfficialUsageExist = (obj) => requests({
    url: '/getOfficialUsageExist',
    method: 'get',
    params: {
        abbreviation: obj.projName,
        month: obj.month
    }
})

/*获取终端表计*/
export const GetTerminalMeterRecords = (abbreviation, accountNum, month) => requests({
    url: '/getTerminalMeterRecords',
    method: 'get',
    params: {
        abbreviation: abbreviation,
        accountNum: accountNum,
        month: month
    }
})

/*新增 elecReport*/
export const InsertElecReports = (unionid, obj) => requests({
    url: '/insertElecReports',
    method: 'post',
    params: {
        unionid: unionid,
        kind: obj.kind,
        abbreviation: obj.projName,
        month: obj.month,
        unitPrice: obj.unitPrice
    }
})

/*获取户号表用量*/
export const GetOfficialUsages = (abbreviation, month) => requests({
    url: '/getOfficialUsages',
    method: 'get',
    params: {
        abbreviation: abbreviation,
        month: month,
    }
})

/*新增户号表用量*/
export const InsertOfficialElecUsage = (meterId, obj) => requests({
    url: '/insertOfficialElecUsage',
    method: 'post',
    params: {
        meterId: meterId,
        month: obj.month,
        usage: obj.usage,
        amount: obj.amount
    }
})

/*获取计费方案 - 统计*/
export const GetElecBillingPlanStatistic = (obj) => requests({
    url: '/getElecBillingPlanStatistic',
    method: 'get',
    params: {
        abbreviation: obj.projName,
        clientName: obj.clientName,
    }
})

/*获取当前电费计费方案*/
export const GetCurrentElecBillPlans = (clientId) => requests({
    url: '/getCurrentElecBillPlans',
    method: 'get',
    params: {
        clientId: clientId
    }
})

/*获取历史电费计费方案*/
export const GetHistoryElecBillPlans = (clientId) => requests({
    url: '/getHistoryElecBillPlans',
    method: 'get',
    params: {
        clientId: clientId
    }
})

/*更新电费方案结束时间*/
export const UpdateElecBillPlanEndDate = (obj) => requests({
    url: '/updateElecBillPlanEndDate',
    method: 'post',
    params: {
        planId: obj.planId,
        endDate: obj.endDate
    }
})

/*获取非房源缴电费客户*/
export const GetElecClients = (abbreviation) => requests({
    url: '/getElecClients',
    method: 'get',
    params: {
        abbreviation: abbreviation
    }
})

/*获取客户对应电表*/
export const GetElecBillingPlanMeters = (clientId) => requests({
    url: '/getElecBillingPlanMeters',
    method: 'get',
    params: {
        clientId: clientId
    }
})

/* 新 =》 可生成计费方案的电表 */
export const GetPlanableElecMeters = (clientId) => requests({
    url: '/getPlanableElecMeters',
    method: 'get',
    params: {
        clientId: clientId
    }
})


/*获取已存在的计费方案*/
export const GetExistElecBillPlans = (obj) => requests({
    url: '/getExistElecBillPlans',
    method: 'get',
    params: {
        meterIds: obj.meterIds.join(','),
        startDate: obj.startDate,
        endDate: obj.endDate
    }
})

/*新增计费方案*/
export const InsertElecBillPlans = (obj) => requests({
    url: '/insertElecBillPlans',
    method: 'post',
    params: {
        abbreviation: obj.projName,
        clientId: obj.clientId,
        paymentMethod: obj.paymentMethod,
        meterIds: obj.meterIds.join(','),
        unitPrice: obj.unitPrice,
        payCycle: obj.payCycle,
        startDate: obj.startDate,
        endDate: obj.endDate
    }
})

/*获取月度 - 客户 电费账单*/
export const GetMonthClientElecBills = (obj) => requests({
    url: '/getMonthClientElecBills',
    method: 'get',
    params: {
        billMonth: obj.billMonth,
        abbreviation: obj.projName,
        clientName: obj.clientName
    }
})


/*获取电费账单统计*/
export const GetElecBills = (obj) => requests({
    url: '/getElecBills',
    method: 'get',
    params: {
        abbreviation: obj.projName,
        clientName: obj.clientName,
    }
})

/*获取客户电费账单*/
export const GetClientMonthElecBills = (clientId) => requests({
    url: '/getClientMonthElecBills',
    method: 'get',
    params: {
        clientId: clientId
    }
})

/*获取 客户 月度 账单*/
export const GetClientMonthElecBill = (clientId, month) => requests({
    url: '/getClientMonthElecBill',
    method: 'get',
    params: {
        clientId: clientId,
        month: month
    }
})

/*获取 客户 月度 电费账单*/
export const GetMonthElecBills = (clientId, month) => requests({
    url: '/getMonthElecBills',
    method: 'get',
    params: {
        clientId: clientId,
        month: month
    }
})

/*获取 客户 月度 其他电费账单*/
export const GetOtherMonthElecBills = (clientId, month) => requests({
    url: '/getOtherMonthElecBills',
    method: 'get',
    params: {
        clientId: clientId,
        month: month
    }
})

/*查询 电费账单 应收金额 修改记录*/
export const GetElecBillAmountChangeRecords = (clientId, month) => requests({
    url: '/getElecBillAmountChangeRecords',
    method: 'get',
    params: {
        clientId: clientId,
        month: month
    }
})

/*获取 电费账单 支付记录*/
export const GetElecBillPaymentRecords = (clientId, month) => requests({
    url: '/getElecBillPaymentRecords',
    method: 'get',
    params: {
        clientId: clientId,
        month: month
    }
})

/*更新电费账单应收金额*/
export const UpdateElecBillAmount = (clientId, month, amount, remark) => requests({
    url: '/updateElecBillAmount',
    method: 'post',
    params: {
        clientId: clientId,
        month: month,
        amount: amount,
        remark: remark
    }
})

/*新增 电费 付款记录*/
export const InsertElecBillPaymentRecord = (unionid, clientId, month, obj) => requests({
    url: '/insertElecBillPaymentRecord',
    method: 'post',
    params: {
        unionid: unionid,
        clientId: clientId,
        month: month,
        clientName: obj.clientName,
        paidAmount: obj.paidAmount,
        paidDate: obj.paidDate,
        remark: obj.remark
    }
})

/*更新 电费 实收金额*/
export const UpdateElecBillPaidIn = (clientId, month) => requests({
    url: '/updateElecBillPaidIn',
    method: 'post',
    params: {
        clientId: clientId,
        month: month
    }
})

/*新增其他账单*/
export const InsertOtherBill = (obj) => requests({
    url: '/insertOtherBill',
    method: 'post',
    params: {
        billType: obj.billType,
        clientName: obj.clientName,
        clientId: obj.clientId,
        kind: obj.kind,
        month: obj.month,
        amount: obj.amount,
        remark: obj.remark
    }
})

/*电费账单 pdf 导出*/
export const ExportElecBillPDF = (abbreviation, obj) => requests({
    url: '/exportElecBillPDF',
    method: 'get',
    responseType: 'blob',
    params: {
        abbreviation: abbreviation,
        month: obj.month,
        clientIds: obj.clientIds.join(',')
    }
})

/*获取电费账单客户*/
export const GetElecBillClients = (abbreviation,month,paymentMethod) => requests({
    url: '/getElecBillClients',
    method: 'get',
    params: {
        abbreviation: abbreviation,
        month: month,
        paymentMethod: paymentMethod
    }
})

/*获取日常汇报*/
export const GetMyDailyReports = (unionid, obj, pageSize, currentPage) => requests({
    url: '/getMyDailyReports',
    method: 'get',
    params: {
        unionid: unionid,
        timeRange: obj.timeRange.join(','),
        companyName: obj.companyName,
        pageSize: pageSize,
        currentPage: currentPage
    }
})

/*获取日常汇报统计*/
export const GetMyDailyReportsCount = (unionid, obj) => requests({
    url: '/getMyDailyReportsCount',
    method: 'get',
    params: {
        unionid: unionid,
        timeRange: obj.timeRange.join(','),
        companyName: obj.companyName
    }
})

/*获取 日常汇报 detail*/
export const GetDailyReportDetail = (unionid, id) => requests({
    url: '/dailyReportDetail',
    method: 'get',
    params: {
        unionid: unionid,
        id: id
    }
})

/* 修改 日常汇报 */
export const EditDailyReport = (id, obj) => requests({
    url: '/editDailyReport',
    method: 'post',
    params: {
        id: id,
        visitTime: obj.visitTime,
        kind: obj.kind,
        mainBusiness: obj.mainBusiness,
        address: obj.address,
        receptionist: obj.receptionist,
        contactInfo: obj.contactInfo,
        wechat: obj.wechat,
        currentStage: obj.currentStage,
        visitContent: obj.visitContent,
        coordinator: obj.coordinator.join(',')
    }
})


/*新增 日常汇报 评论*/
export const InsertDailyReportComment = (obj) => requests({
    url: '/addDailyReportComment',
    method: 'post',
    params: {
        unionid: obj.unionid,
        dailyReportId: obj.dailyReportId,
        replyUnionid: obj.replyUnionid,
        kind: obj.kind,
        comment: obj.comment
    }
})

/*获取 日常汇报 评论*/
export const GetDailyReportComments = (id, unionid) => requests({
    url: '/dailyReportComments',
    method: 'get',
    params: {
        id: id,
        unionid: unionid
    }
})

/*更新评论已读状态*/
export const UpdateDailyReportCommentHaveRead = (unionid, id) => requests({
    url: '/updateDailyReportCommentHaveRead',
    method: 'post',
    params: {
        unionid: unionid,
        id: id
    }
})

/* 更新日报已读 */
export const UpdateDailyReportRead = (unionid, followUpId) => requests({
    url: '/updateDailyReportRead',
    method: 'post',
    params: {
        unionid: unionid,
        id: followUpId
    }
})

/*未读 商机 + 日报 评论数量*/
export const GetUnreadBusinessComments = (unionid) => requests({
    url: '/unreadCommentCount',
    method: 'get',
    params: {
        unionid: unionid
    }
})

/*获取 相似的 日报客户*/
export const GetSimilarDailyReports = (unionid, companyName) => requests({
    url: '/searchDailyReport',
    method: 'get',
    params: {
        unionid: unionid,
        companyName: companyName
    }
})

/*新增日报*/
export const InsertDailyReport = (obj, unionid) => requests({
    url: '/addDailyReport',
    method: 'post',
    params: {
        visitTime: obj.visitTime,
        kind: obj.kind,
        companyName: obj.companyName,
        mainBusiness: obj.mainBusiness,
        address: obj.address,
        receptionist: obj.receptionist,
        contactInfo: obj.contactInfo,
        wechat: obj.wechat,
        currentStage: obj.currentStage,
        visitContent: obj.visitContent,
        coordinator: obj.coordinator.join(','),
        unionid: unionid
    }
})

/*获取我的商机*/
export const GetMyBusinessList = (unionid, obj, pageSize, currentPage) => requests({
    url: '/getMyBusinessList',
    method: 'get',
    params: {
        unionid: unionid,
        projName: obj.projName == '全部' ? '' : obj.projName,
        businessName: obj.businessName,
        currentStage: obj.currentStage == '全部' ? '' : obj.currentStage,
        pageSize: pageSize,
        currentPage: currentPage
    }
})

/* 获取我的商机统计 */
export const GetMyBusinessListCount = (unionid, obj) => requests({
    url: '/getMyBusinessListCount',
    method: 'get',
    params: {
        unionid: unionid,
        projName: obj.projName == '全部' ? '' : obj.projName,
        businessName: obj.businessName,
        currentStage: obj.currentStage == '全部' ? '' : obj.currentStage
    }
})


/*获取商机详情*/
export const GetBusinessInfo = (id) => requests({
    url: '/businessInfoDetail',
    method: 'get',
    params: {
        id: id
    }
})

/* 获取跟进 */
export const GetBusinessFollowUp = (id) => requests({
    url: '/getBusinessFollowUp',
    method: 'get',
    params: {
        id: id
    }
})

/*获取跟进记录*/
export const GetBusinessFollowUps = (unionid, id) => requests({
    url: '/businessFollowUps',
    method: 'get',
    params: {
        unionid: unionid,
        id: id
    }
})

export const DeteleBusinessFollowUp = (id) => requests({
    url: '/deteleBusinessFollowUp',
    method: 'post',
    params: {
        id: id
    }
})

/*获取商机评论*/
export const GetBusinessComments = (unionid, id) => requests({
        url: '/businessComments',
        method: 'get',
        params: {
            unionid: unionid,
            id: id
        }
    }
)

/*新增商机评论*/
export const InsertBusinessComment = (obj) => requests({
    url: '/addBusinessComment',
    method: 'post',
    params: {
        unionid: obj.unionid,
        businessId: obj.businessId,
        kind: obj.kind,
        replyUnionid: obj.replyUnionid,
        comment: obj.comment
    }
})

/*更新商机评论已读状态*/
export const UpdateBusinessCommentHaveRead = (unionid, businessId) => requests({
    url: '/updateBusinessCommentHaveRead',
    method: 'post',
    params: {
        unionid: unionid,
        businessId: businessId
    }
})

/* 更新跟进已读 */
export const UpdateBusinessFollowUpRead = (followUpId, unionid) => requests({
    url: '/updateBusinessFollowUpRead',
    method: 'post',
    params: {
        followUpId: followUpId,
        unionid: unionid
    }
})

/*获取商机修改记录*/
export const GetBusinessInfoUpdateRecords = (id) => requests({
    url: '/getBusinessInfoUpdateRecords',
    method: 'get',
    params: {
        id: id
    }
})

/*获取重复商机*/
export const GetRepeatBusinesses = (businessName, ownName) => requests({
    url: '/repeatBusiness',
    method: 'get',
    params: {
        businessName: businessName,
        ownName: ownName
    }
})

/*修改商机*/
export const UpdateBusinessInfo = (id, obj, unionid) => requests({
    url: '/updateBusinessInfo',
    method: 'post',
    params: {
        id: id,
        businessName: obj.businessName,
        kind: obj.kind,
        projName: obj.projName,
        mainBusiness: obj.mainBusiness,
        address: obj.address,
        contactPerson: obj.contactPerson,
        contactInfo: obj.contactInfo,
        wechat: obj.wechat,
        origin: obj.origin,
        remark: obj.remark,
        unionid: obj.unionid.join(','),
        houseIds: obj.houseIds.join(','),
        houseNames: obj.houseNames.join(','),
        operatorId: unionid,
    }
})

/*新增跟进*/
export const InsertBusinessFollowUp = (obj, unionid) => requests({
    url: '/insertBusinessFollowUp',
    method: 'post',
    params: {
        businessId: obj.businessId,
        followUpDate: obj.followUpDate,
        followUpWay: obj.followUpWay,
        currentStage: obj.currentStage,
        coordinator: obj.coordinator.join(','),
        visitContent: obj.visitContent,
        unionid: unionid
    }
})

/*更新跟进*/
export const UpdateBusinessFollowUp = (id, obj, unionid, businessId) => requests({
    url: '/updateBusinessFollowUp',
    method: 'post',
    params: {
        id: id,
        followUpDate: obj.followUpDate,
        followUpWay: obj.followUpWay,
        currentStage: obj.currentStage,
        coordinator: obj.coordinator.join(','),
        visitContent: obj.visitContent,
        unionid: unionid,
        businessId: businessId
    }
})

/*新增商机*/
export const InsertBusinessInfo = (obj) => requests({
    url: '/insertBusinessInfo',
    method: 'post',
    params: {
        businessName: obj.businessName,
        kind: obj.kind,
        projName: obj.projName,
        mainBusiness: obj.mainBusiness,
        address: obj.address,
        contactPerson: obj.contactPerson,
        contactInfo: obj.contactInfo,
        wechat: obj.wechat,
        origin: obj.origin,
        remark: obj.remark,
        houseIds: obj.houseIds.join(','),
        houseNames: obj.houseNames.join(','),
        unionid: obj.unionid.join(',')
    }
})

/*获取 currentStages */
export const GetCurrentStages = () => requests({
    url: '/getCurrentStages',
    method: 'get'
})

/*获取跟进方式*/
export const GetBusinessFollowUpWays = () => requests({
    url: '/getBusinessFollowUpWays',
    method: 'get'
})

/*获取 客户来源*/
export const GetBusinessOrigins = () => requests({
    url: '/getBusinessOrigins',
    method: 'get'
})

/*查询 商机客户*/
export const SearchBusiness = (obj, pageSize, currentPage) => requests({
    url: '/searchBusiness',
    method: 'get',
    params: {
        clientName: obj.clientName,
        contactPerson: obj.contactPerson,
        contactInfo: obj.contactInfo,
        wechat: obj.wechat,
        pageSize: pageSize,
        currentPage: currentPage
    }
})

/* 查询商机统计 */
export const SearchBusinessCount = (obj) => requests({
    url: '/searchBusinessCount',
    method: 'get',
    params: {
        clientName: obj.clientName,
        contactPerson: obj.contactPerson,
        contactInfo: obj.contactInfo,
        wechat: obj.wechat
    }
})

/* 获取公共商机 统计 */
export const GetPublicBusinessInfosCount = (businessName) => requests({
    url: '/getPublicBusinessInfosCount',
    method: 'get',
    params: {
        businessName: businessName
    }
})

/* 获取公共商机 */
export const GetPublicBusinessInfos = (businessName,pageSize, currentPage) => requests({
    url: '/getPublicBusinessInfos',
    method: 'get',
    params: {
        businessName: businessName,
        pageSize: pageSize,
        currentPage: currentPage
    }
})

/* 领取公共商机 */
export const ReceiveBusinessInfo = (id,unionid) => requests({
    url: '/receiveBusinessInfo',
    method: 'post',
    params: {
        id: id,
        unionid: unionid
    }
})

/*获取 商机权限 - 用户列表*/
export const GetBusinessEmployees = (unionid) => requests({
    url: '/authorityEmployeeList',
    method: 'get',
    params: {
        unionid: unionid
    }
})

/*获取 每日汇总*/
export const GetComplexEveryDay = (obj, pageSize, currentPage) => requests({
    url: '/getComplexEveryDay',
    method: 'get',
    params: {
        startDate: obj.timeRange[0],
        endDate: obj.timeRange[1],
        unionids: obj.searchUnionids.join(','),
        pageSize: pageSize,
        currentPage: currentPage
    }
})

/* 获取每日汇总统计 */
export const GetComplexEveryDayCount = (obj) => requests({
    url: '/getComplexEveryDayCount',
    method: 'get',
    params: {
        startDate: obj.timeRange[0],
        endDate: obj.timeRange[1],
        unionids: obj.searchUnionids.join(',')
    }
})

/*获取 商机 -> 更新已读状态*/
export const GetComplexBusinessDetail = (id, followUpId, unionid) => requests({
    url: '/complexBusinessDetail',
    method: 'get',
    params: {
        id: id,
        followUpId: followUpId,
        unionid: unionid
    }
})

/*获取每人工作汇总*/
export const GetEveryoneWorkCount = (obj, unionid) => requests({
    url: '/everyoneWorkCount',
    method: 'get',
    params: {
        startTime: new Date(obj.timeRange[0]).getTime(),
        endTime: new Date(obj.timeRange[1]).getTime(),
        unionid: unionid
    }
})

/*获取 工作汇总*/
export const GetBusinessEveryOneCount = (unionid, obj, searchUnionid) => requests({
    url: '/getBusinessEveryOneCount',
    method: 'get',
    params: {
        unionid: unionid,
        startDate: obj.timeRange[0],
        endDate: obj.timeRange[1],
        searchUnionid: searchUnionid
    }
})

/*获取全部商机*/
export const GetAllBusinesses = (obj, pageSize, currentPage) => requests({
    url: '/getComplexAllBusinesses',
    method: 'get',
    params: {
        startDate: obj.timeRange[0],
        endDate: obj.timeRange[1],
        businessName: obj.businessName,
        projName: obj.projName == '全部' ? '' : obj.projName,
        currentStage: obj.currentStage == '全部' ? '' : obj.currentStage,
        unionids: obj.searchUnionids.join(','),
        pageSize: pageSize,
        currentPage: currentPage
    }
})

/* 获取全部商机 统计 */
export const GetAllBusinessesCount = (obj) => requests({
    url: '/getBusinessCount',
    method: 'get',
    params: {
        startDate: obj.timeRange[0],
        endDate: obj.timeRange[1],
        businessName: obj.businessName,
        projName: obj.projName == '全部' ? '' : obj.projName,
        currentStage: obj.currentStage == '全部' ? '' : obj.currentStage,
        unionids: obj.searchUnionids.join(',')
    }
})

/*获取未读评论列表*/
export const GetUnreadCommentList = (unionid) => requests({
    url: '/getUnreadCommentList',
    method: 'get',
    params: {
        unionid: unionid
    }
})

/*wuye - 获取 wuyeUserInfo 列表*/
export const GetWuyeUserInfos = (name, phone) => requests({
    url: '/getWuyeUserInfos',
    method: 'get',
    params: {
        name: name,
        phone: phone
    }
})

/* 查询用户详情 */
export const GetWuyeUserDetail = (userUnionid) => requests({
    url: '/getWuyeUserDetail',
    method: 'get',
    params: {
        userUnionid: userUnionid
    }
})


/*wuye - 获取 wuye_user 认证列表*/
export const GetWuyeCertificationObjs = (obj, type) => requests({
    url: '/getWuyeCertificationObjs',
    method: 'get',
    params: {
        projName: obj.projName,
        clientName: obj.clientName,
        name: obj.userName,
        type: type
    }
})

/*更新 wuye_user 认证信息 */
export const UpdateWuyeUser = (id, a, b) => requests({
    url: '/updateWuyeUser',
    method: 'post',
    params: {
        id: id,
        state: a,
        await: b
    }
})

/* 推送客户关系变更通知 */
export const WxSendBoundClientChange = (unionid, clientName, type) => requests({
    url: '/wxSendBoundClientChange',
    method: 'get',
    params: {
        unionid: unionid,
        clientName: clientName,
        type: type
    }
})

/* 推送物业账单通知 */
export const WxSendBillNotice = (projName, months, clientIds, unionid) => requests({
    url: '/wxSendBillNotice',
    method: 'get',
    params: {
        projName: projName,
        months: months,
        clientIds: clientIds,
        unionid: unionid
    }
})

/* 推送缴费通知 */
export const WxSendUrgeNotice = (ids, clientId) => requests({
    url: '/wxSendUrgeNotice',
    method: 'get',
    params: {
        ids: ids,
        clientId: clientId
    }
})

/* 发票领取通知 */
export const WxSendInvoiceNotice = (clientIds) => requests({
    url: '/wxSendInvoiceNotice',
    method: 'get',
    params: {
        clientIds: clientIds
    }
})


/*获取 wuye_elecCertificates */
export const GetWuyeElecCertificates = (obj, state) => requests({
    url: '/getWuyeElecCertificates',
    method: 'get',
    params: {
        abbreviation: obj.projName,
        firstDay: obj.timeRange[0],
        lastDay: obj.timeRange[1],
        state: state
    }
})

/* 获取 wuye_elecCertificate 、wuye_userInfo、wuye_user */
export const GetElecCertificateDetail = (id) => requests({
    url: '/getElecCertificateDetail',
    method: 'get',
    params: {
        id: id
    }
})

/* 更新 wuye_elecCertificate 的 amount */
export const UpdateWuyeElecCertificateAmount = (id, amount) => requests({
    url: '/updateWuyeElecCertificateAmount',
    method: 'post',
    params: {
        id: id,
        amount: amount
    }
})

/* 更新 wuye_elecCertificate 的 checkState */
export const UpdateWuyeElecCertificateCheckState = (id, state) => requests({
    url: '/updateWuyeElecCertificateCheckState',
    method: 'post',
    params: {
        id: id,
        state: state
    }
})

/* 根据时间获取ElecPayments */
export const GetWuyeElecPayments = (obj) => requests({
    url: '/getWuyeElecPayments',
    method: 'get',
    params: {
        abbreviation: obj.projName,
        firstDay: obj.timeRange[0],
        lastDay: obj.timeRange[1]
    }
})

/* 获取 elecPaymentDetail */
export const GetElecPaymentDetail = (id) => requests({
    url: '/getElecPaymentDetail',
    method: 'get',
    params: {
        id: id
    }
})

/* 获取 wuyeElecInvoices */
/*export const GetWuyeElecInvoices = (unionid,obj) => requests({
    url: '/getWuyeElecInvoices',
    method: 'get',
    params: {
        unionid: unionid,
        type: obj.type,
        abbreviation: obj.projName,
        firstDay: obj.timeRange[0],
        lastDay: obj.timeRange[1]
    }
})*/

/* 获取 wuyeElecInvoiceDetail */
export const GetWuyeElecInvoiceDetail = (id) => requests({
    url: '/getWuyeElecInvoiceDetail',
    method: 'get',
    params: {
        id: id
    }
})

/* 更新 wuyeElecInvoice Content */
export const UpdateElecInvoiceContent = (id, content) => requests({
    url: '/updateElecInvoiceContent',
    method: 'post',
    params: {
        id: id,
        content: content
    }
})

/* 查找发票信息 */
export const SearchFpqqlsh = (paymentMethod,fpqqlsh) => requests({
    url: '/searchFpqqlsh',
    method: 'get',
    params: {
        paymentMethod: paymentMethod,
        fpqqlsh: fpqqlsh
    }
})

/* 开电子票 */
export const MakeElecInvoice = (id) => requests({
    url: '/makeElecInvoice',
    method: 'get',
    params: {
        id: id
    }
})

/* 生效发票 */
export const TakeEffectElecInvoice = (id, type, fpqqlsh, number) => requests({
    url: '/takeEffectElecInvoice',
    method: 'post',
    params: {
        id: id,
        type: type,
        fpqqlsh: fpqqlsh,
        number: number
    }
})

/* 领取发票 */
export const PickUpElecInvoices = (unionid, checked) => requests({
    url: '/pickUpElecInvoices',
    method: 'post',
    params: {
        unionid: unionid,
        ids: checked.join(',')
    }
})

/* 拒绝开票 */
export const RejectElecInvoice = (id) => requests({
    url: '/rejectElecInvoice',
    method: 'post',
    params: {
        id: id
    }
})

/*推送电费账单*/
export const SendWxBillMessage = (month) => requests({
    url: '/sendWxBillMessage',
    method: 'get',
    params: {
        month: month
    }
})

/*新增 validElecBillMonth*/
export const InsertValidElecBillMonth = (month) => requests({
    url: '/insertValidElecBillMonth',
    method: 'post',
    params: {
        month: month
    }
})

/* 推送 纸质票 领取 消息 */
export const SendElecInvoicePickUp = (checked) => requests({
    url: '/sendElecInvoicePickUp',
    method: 'get',
    params: {
        ids: checked.join(',')
    }
})

/* 客户领票 */
export const ClientPickUpElecInvoice = (id) => requests({
    url: '/clientPickUpElecInvoice',
    method: 'post',
    params: {
        id: id
    }
})

/* U8 项目列表 */
export const GetWuyeFitemss97s = () => requests({
    url: '/getWuyeFitemss97s',
    method: 'get'
})

/* 获取 projNameDetail */
export const GetProjNameDetail = (projName) => requests({
    url: '/getProjNameDetail',
    method: 'get',
    params: {
        projName: projName
    }
})

/* 物业客户属性列表 */
export const GetWuyeClientAttributes = () => requests({
    url: '/getWuyeClientAttributes',
    method: 'get'
})

/* 用户物业权限 */
export const GetWuyeAuthority = (unionid) => requests({
    url: '/getWuyeAuthority',
    method: 'get',
    params: {
        unionid: unionid
    }
})

/* 获取 U8clientCode 不为 null 的物业客户 */
export const GetWuyeClientsValidCode = (projName) => requests({
    url: '/getWuyeClientsValidCode',
    method: 'get',
    params: {
        projName: projName
    }
})

/* 获取有物业费账单的客户 */
export const GetWuyeClientsWithInvoicableBills = (projName) => requests({
    url: '/getWuyeClientsWithInvoicableBills',
    method: 'get',
    params: {
        projName: projName
    }
})

/* 获取全部物业客户 */
export const GetAllWuyeClients = (projName, clientName) => requests({
    url: '/getAllWuyeClients',
    method: 'get',
    params: {
        projName: projName,
        clientName: clientName
    }
})

/* 获取物业客户 按 生效/未生效 分类 */
export const GetWuyeClients = (state, projName, clientName) => requests({
    url: '/getWuyeClients',
    method: 'get',
    params: {
        state: state,
        projName: projName,
        clientName: clientName
    }
})

/* 保存 U8clientCode 并使客户生效 */
export const TakeEffectWuyeClient = (clientId, U8clientCode) => requests({
    url: '/takeEffectWuyeClient',
    method: 'post',
    params: {
        clientId: clientId,
        U8clientCode: U8clientCode
    }
})


/* 获取物业客户 详情 */
export const GetWuyeClientDetail = (clientId) => requests({
    url: '/getWuyeClientDetail',
    method: 'get',
    params: {
        clientId: clientId
    }
})


/* 更新物业客户 信息 */
export const UpdateWuyeClient = (clientId, obj) => requests({
    url: '/updateWuyeClient',
    method: 'post',
    params: {
        clientId: clientId,
        paymentMethod: obj.paymentMethod,
        attribute: obj.attribute.join(','),
        contactPerson: obj.contactPerson,
        contactPhone: obj.contactPhone,
        enterpriseCode: obj.enterpriseCode,
        taxNumber: obj.taxNumber,
        bank: obj.bank,
        bankAccount: obj.bankAccount,
        address: obj.address,
        telephone: obj.telephone,
        entryDate: obj.entryDate,
        exitDate: obj.exitDate,
        remark: obj.remark
    }
})

/* 获取客户物业合同 */
export const GetWuyeContract = (clientId) => requests({
    url: '/getWuyeContract',
    method: 'get',
    params: {
        clientId: clientId
    }
})

/* 获取 endDate >= date 的物业合同 */
export const GetWuyeContractsByDate = (clientId, billMonth) => requests({
    url: '/getWuyeContractsByDate',
    method: 'get',
    params: {
        clientId: clientId,
        billMonth: billMonth
    }
})

/* 获取物业合同详情 */
export const GetWuyeContractDetail = (contractNo) => requests({
    url: '/getWuyeContractDetail',
    method: 'get',
    params: {
        contractNo: contractNo
    }
})

/* 更新物业合同详情 */
export const UpdateWuyeContractDetail = (obj) => requests({
    url: '/updateWuyeContractDetail',
    method: 'post',
    params: {
        contractNo: obj.contractNo,
        endDate: obj.endDate,
        remark: obj.remark
    }
})

/* 获取当前时间内 没有物业合同 的房源 */
export const GetHouseInfoWithoutWuyeContracts = (projName, startDate, endDate) => requests({
    url: '/getHouseInfoWithoutWuyeContracts',
    method: 'get',
    params: {
        projName: projName,
        startDate: startDate,
        endDate: endDate
    }
})

/* 新增物业合同 */
export const InsertWuyeContract = (projName, projCode, clientId, clientName, clientCode, obj, startDate, endDate) => requests({
    url: '/insertWuyeContract',
    method: 'post',
    params: {
        projName: projName,
        projCode: projCode,
        clientId: clientId,
        clientName: clientName,
        clientCode: clientCode,
        houseIds: obj.houseIds.join(','),
        contractNo: obj.contractNo,
        signDate: obj.signDate,
        startDate: startDate,
        endDate: endDate,
        billingCycle: obj.billingCycle,
        remark: obj.remark
    }
})

/* 新增物业客户 */
export const InsertWuyeClient = (obj, unionid) => requests({
    url: '/insertWuyeClient',
    method: 'post',
    params: {
        paymentMethod: obj.paymentMethod,
        citemcode: obj.citemcode,
        clientName: obj.clientName,
        type: obj.type,
        attribute: obj.attribute.join(','),
        clientId: obj.clientId,
        abbreviation: obj.abbreviation,
        contactPerson: obj.contactPerson,
        contactPhone: obj.contactPhone,
        enterpriseCode: obj.enterpriseCode,
        taxNumber: obj.taxNumber,
        bank: obj.bank,
        bankAccount: obj.bankAccount,
        address: obj.address,
        telephone: obj.telephone,
        entryDate: obj.entryDate,
        exitDate: obj.exitDate,
        remark: obj.remark,
        unionid: unionid
    }
})

/* 获取可新增 payment 的电费账单 */
export const GetAddablePayment = (month, projName) => requests({
    url: '/getAddablePayment',
    method: 'get',
    params: {
        month: month,
        projName: projName
    }
})

/* 获取新增 payment 的 用户信息+账单信息 */
export const GetAddablePaymentInfo = (clientId, month) => requests({
    url: '/getAddablePaymentInfo',
    method: 'get',
    params: {
        clientId: clientId,
        month: month
    }
})

/* 获取 可开票的 电费付款记录 */
export const GetInvoicableElecPayments = (projName, billMonth) => requests({
    url: '/getInvoicableElecPayments',
    method: 'get',
    params: {
        projName: projName,
        billMonth: billMonth
    }
})

/* 获取 已开的电费发票 */
export const GetWuyeElecInvoices = (projName, billMonth) => requests({
    url: '/getWuyeElecInvoices',
    method: 'get',
    params: {
        projName: projName,
        billMonth: billMonth
    }
})

/* 获取 电费发票 需要的信息 */
export const GetElecInvoiceInfo = (payType, clientId, billMonth) => requests({
    url: '/getElecInvoiceInfo',
    method: 'get',
    params: {
        payType: payType,
        clientId: clientId,
        billMonth: billMonth
    }
})

/* 开电子发票 */
export const OpenElecInvoice = (clientId, amount, content, billMonth) => requests({
    url: '/openElecInvoice',
    method: 'get',
    params: {
        clientId: clientId,
        amount: amount,
        content: content,
        billMonth: billMonth
    }
})

/* 生效电子发票 */
export const TakeEffectElectronicElecInvoice = (clientId, billMonth, fpqqlsh, amount, unionid) => requests({
    url: '/takeEffectElectronicElecInvoice',
    method: 'post',
    params: {
        clientId: clientId,
        billMonth: billMonth,
        fpqqlsh: fpqqlsh,
        amount: amount,
        unionid: unionid
    }
})

/* 新增纸质发票 */
export const InsertPaperElecInvoice = (clientId, amount, content, billMonth, number, unionid) => requests({
    url: '/insertPaperElecInvoice',
    method: 'post',
    params: {
        clientId: clientId,
        amount: amount,
        content: content,
        billMonth: billMonth,
        number: number,
        unionid: unionid
    }
})

/* 获取 U8收费项目 */
export const GetWuyeInventories = () => requests({
    url: '/getWuyeInventories',
    method: 'get'
})

/* 获取 物业账单（按账单） */
export const GetWuyeBills = (projName, billMonth, clientName) => requests({
    url: '/getWuyeBills',
    method: 'get',
    params: {
        projName: projName,
        billMonth: billMonth,
        clientName: clientName
    }
})

/* 获取 物业账单（按客户） */
export const GetClientWuyeBillsCount = (projName, billMonth, clientName) => requests({
    url: '/getClientWuyeBillsCount',
    method: 'get',
    params: {
        projName: projName,
        clientName: clientName
    }
})

/* 获取 有效物业合同 客户 */
export const GetValidContractWuyeClients = (projName, billMonth) => requests({
    url: '/getValidContractWuyeClients',
    method: 'get',
    params: {
        projName: projName,
        billMonth: billMonth
    }
})

/* 获取 客户会产生物业费的 clientIds */
export const GetPossiblePropertyBillWuyeClientIds = (projName, billMonth) => requests({
    url: '/getPossiblePropertyBillWuyeClientIds',
    method: 'get',
    params: {
        projName: projName,
        billMonth: billMonth
    }
})

/* 导出 物业费能耗费导入模板 */
export const ExportPropertyBillTemplateExcel = (billMonth, clientIdArr) => requests({
    url: '/exportPropertyBillTemplateExcel',
    responseType: 'blob',
    method: 'get',
    params: {
        billMonth: billMonth,
        clientIds: clientIdArr.join(',')
    }
})

/* 新增 wuyeBill */
export const InsertWuyeBill = (type, projName, billMonth, feeCode, obj, unionid, houseNames) => requests({
    url: '/insertWuyeBill',
    method: 'post',
    params: {
        type: type,
        projName: projName,
        billMonth: billMonth,
        feeCode: feeCode,
        clientId: obj.clientId,
        paymentMethod:obj.paymentMethod,
        contractNo: obj.contractNo,
        quantity: obj.quantity,
        unitPrice: obj.unitPrice,
        amount: obj.amount,
        reliefAmount: obj.reliefAmount,
        startDate: obj.timeRange[0],
        endDate: obj.timeRange[1],
        dueDate: obj.dueDate,
        remark: obj.remark,
        payer: obj.payer,
        unionid: unionid,
        houseNames: houseNames
    }
})

/* 获取 有房源关系的物业客户（租客、业主） */
export const GetWuyeHouseClients = (projName) => requests({
    url: '/getWuyeHouseClients',
    method: 'get',
    params: {
        projName: projName
    }
})

/* 导出水费模板 */
export const ExportWaterBillTemplateExcel = (billMonth, clientIdArr) => requests({
    url: '/exportWaterBillTemplateExcel',
    responseType: 'blob',
    method: 'get',
    params: {
        billMonth: billMonth,
        clientIds: clientIdArr.join(',')
    }
})

/* 获取物业账单详情 */
export const GetWuyeBillDetail = (id) => requests({
    url: '/getWuyeBillDetail',
    method: 'get',
    params: {
        id: id
    }
})

/* 更新物业账单备注 */
export const UpdateWuyeBillDetail = (id, paymentMethod,collection, reliefAmount, remark) => requests({
    url: '/updateWuyeBillDetail',
    method: 'post',
    params: {
        id: id,
        paymentMethod: paymentMethod,
        collection: collection,
        reliefAmount: reliefAmount,
        remark: remark
    }
})

/* 获取客户的物业账单明细 */
export const GetClientWuyeBillLists = (clientId) => requests({
    url: '/getClientWuyeBillLists',
    method: 'get',
    params: {
        clientId: clientId
    }
})

/* 获取客户的可开票物业账单 */
export const GetInvoicableWuyeBills = (clientId) => requests({
    url: '/getInvoicableWuyeBills',
    method: 'get',
    params: {
        clientId: clientId
    }
})

/* 获取可开票得物业账单 客服-已收款 财务-全部 */
export const GetInvoicableBills = (clientId,unionid) => requests({
    url: '/getInvoicableBills',
    method: 'get',
    params: {
        clientId: clientId,
        unionid: unionid
    }
})


/* 登记物业发票 */
export const RegisterWuyeInvoice = (ids, number, value1, unionid) => requests({
    url: '/registerWuyeInvoice',
    method: 'post',
    params: {
        ids: ids,
        number: number,
        value1: value1,
        unionid: unionid
    }
})

/* 标记物业发票 */
export const MarkWuyeInvoice = (ids) => requests({
    url: '/markWuyeInvoice',
    method: 'post',
    params: {
        ids: ids
    }
})

/* 获取各类收费项目统计 */
export const GetWuyeInventoryCount = (projName) => requests({
    url: '/getWuyeInventoryCount',
    method: 'get',
    params: {
        projName: projName
    }
})

/* 统计各项目 amount、reliefAmount、receivedAmount */
export const GetProjWuyeBillCount = (projName) => requests({
    url: '/getProjWuyeBillCount',
    method: 'get',
    params: {
        projName: projName
    }
})

/* 获取 wuyeClient 的绑定状态 */
export const GetWuyeClientBindingStates = (projName, clientName) => requests({
    url: '/getWuyeClientBindingStates',
    method: 'get',
    params: {
        projName: projName,
        clientName: clientName
    }
})

/* 获取 elecPayments - wuyeBill信息 */
export const GetElecPaymentsWithBills = (obj) => requests({
    url: '/getElecPaymentsWithBills',
    method: 'get',
    params: {
        projName: obj.projName,
        firstDay: obj.timeRange[0],
        lastDay: obj.timeRange[1]
    }
})

/* 获取 用户 绑定客户 账单统计 */
export const GetBoundClientWuyeBillCount = (obj) => requests({
    url: '/getBoundClientWuyeBillCount',
    method: 'get',
    params: {
        projName: obj.projName,
        months: obj.months.join(',')
    }
})

/* 获取 有未缴费账单的物业客户 */
export const GetBoundClientWithUnpaidBills = (projName) => requests({
    url: '/getBoundClientWithUnpaidBills',
    method: 'get',
    params: {
        projName: projName
    }
})

/* 获取 未缴费的物业账号 */
export const GetWuyeClientUnpaidBills = (clientId) => requests({
    url: '/getWuyeClientUnpaidBills',
    method: 'get',
    params: {
        clientId: clientId
    }
})

/* 获取 所有 state = 1 的物业客户 */
export const GetValidClientsByProjName = (projName) => requests({
    url: '/getValidClientsByProjName',
    method: 'get',
    params: {
        projName: projName
    }
})

/* 获取可开电子发票的账单 */
export const GetElectronicInvoicableWuyeBills = (projName) => requests({
    url: '/getElectronicInvoicableWuyeBills',
    method: 'get',
    params: {
        projName: projName
    }
})

/* 获取电子发票所需要的信息 */
export const GetElectronicInvoiceInfo = (id) => requests({
    url: '/getElectronicInvoiceInfo',
    method: 'get',
    params: {
        id: id
    }
})

/* 电子发票请求 */
export const RequestElectronicInvoice = (billId, content) => requests({
    url: '/requestElectronicInvoice',
    method: 'get',
    params: {
        billId: billId,
        content: content
    }
})

/* 回填 invoiceInfo */
export const BackfillWuyeBillInvoice = (id, fpqqlsh, unionid) => requests({
    url: '/backfillWuyeBillInvoice',
    method: 'post',
    params: {
        id: id,
        fpqqlsh: fpqqlsh,
        unionid: unionid
    }
})

/* 获取已开电子票的账单 */
export const GetIssuedElectronicInvoices = (obj) => requests({
    url: '/getIssuedElectronicInvoices',
    method: 'get',
    params: {
        projName: obj.projName,
        months: obj.months.join(',')
    }
})

/* 获取车位列表 */
export const GetParkingList = (obj) => requests({
    url: '/getParkingList',
    method: 'get',
    params: {
        projName: obj.projName,
        parkingNo: obj.parkingNo,
        clientName: obj.clientName
    }
})

/* 获取车位详情 */
export const GetParkingInfo = (projName, parkingNo) => requests({
    url: '/getParkingInfo',
    method: 'get',
    params: {
        projName: projName,
        parkingNo: parkingNo
    }
})

/* 更新车位信息 */
export const UpdateParking = (projName, parkingNo, obj) => requests({
    url: '/updateParking',
    method: 'post',
    params: {
        projName: projName,
        parkingNo: parkingNo,
        parkingAttribute: obj.parkingAttribute,
        rentPrice: obj.rentPrice,
        managementPrice: obj.managementPrice,
        clientId: obj.clientId,
        remark: obj.remark
    }
})

/* 获取 parkingRecord 数量 */
export const GetParkingRecordCount = (projName) => requests({
    url: '/getParkingRecordCount',
    method: 'get',
    params: {
        projName: projName
    }
})

/* 分页查询 parkingRecord list */
export const GetParkingRecordList = (obj, pageSize, currentPage) => requests({
    url: '/getParkingRecordList',
    method: 'get',
    params: {
        projName: obj.projName,
        clientName: obj.clientName,
        parkingNo: obj.parkingNo,
        pageSize: pageSize,
        currentPage: currentPage
    }
})

/* 查询 parkingRecordDetail */
export const GetParkingRecordDetail = (id) => requests({
    url: '/getParkingRecordDetail',
    method: 'get',
    params: {
        id: id
    }
})

/* 新增 parkingRecord */
export const InsertParkingRecord = (projName, obj, amount, unionid) => requests({
    url: '/insertParkingRecord',
    method: 'post',
    params: {
        projName: projName,
        clientId: obj.clientId,
        parkingNos: obj.parkingNos,
        startDate: obj.timeRange[0],
        endDate: obj.timeRange[1],
        months: obj.months,
        payer: obj.payer,
        remark: obj.remark,
        unionid: unionid
    }
})

/* 获取 同步至账单的 parkingRecord 信息 */
export const GetGenerateParkingRecords = (ids) => requests({
    url: '/getGenerateParkingRecords',
    method: 'get',
    params: {
        ids: ids
    }
})

/* parkingRecords 同步至账单 */
export const GenerateParkingRecords = (ids, obj, unionid) => requests({
    url: '/generateParkingRecords',
    method: 'post',
    params: {
        ids: ids,
        billMonth: obj.billMonth,
        clientId: obj.clientId,
        payer: obj.payer,
        startDate: obj.timeRange[0],
        endDate: obj.timeRange[1],
        amount: obj.amount,
        reliefAmount: obj.reliefAmount,
        dueDate: obj.dueDate,
        remark: obj.remark,
        unionid: unionid
    }
})

/* 查询房源2 */
export const GetHouseInfos2 = (projName, houseName) => requests({
    url: '/getHouseInfos2',
    method: 'get',
    params: {
        projName: projName == '全部' ? '%' : projName,
        houseName: houseName,
        clientName: ''
    }
})

/* 物业电子发票 - 获取公司、用户信息 */
export const GetInvoiceClientInfo = (clientId) => requests({
    url: '/getInvoiceClientInfo',
    method: 'get',
    params: {
        clientId: clientId
    }
})

/* 获取商品明细 */
export const GetInvoiceDetail = (selectIds) => requests({
    url: '/getInvoiceDetail',
    method: 'get',
    params: {
        selectIds: selectIds.join(',')
    }
})

/* 数电发票请求 */
export const RequestShuDianInvoice = (obj,selectIds) => requests({
    url: '/requestShuDianInvoice',
    method: 'get',
    params: {
        phone: obj.phone,
        email: obj.email,
        paymentMethod: obj.paymentMethod,
        invoiceLine: obj.invoiceLine,
        clientName: obj.clientName,
        taxNumber: obj.taxNumber,
        bank: obj.bank,
        bankAccount: obj.bankAccount,
        address: obj.address,
        telephone: obj.telephone,
        remark: obj.content,
        selectIds: selectIds.join(',')
    }
})

/* 回填invoiceInfo */
export const BackFillInvoiceInfo = (selectIds,fpqqlsh,invoiceFirst,unionid) => requests({
    url: '/backFillInvoiceInfo',
    method: 'post',
    params: {
        selectIds: selectIds.join(','),
        fpqqlsh: fpqqlsh,
        invoiceFirst: invoiceFirst,
        unionid: unionid
    }
})

/* 微信小程序推送 invoiceInfo */
export const PushInvoiceNotification = (clientId) => requests({
    url: '/pushInvoiceNotification',
    method: 'get',
    params: {
        clientId: clientId
    }
})

/* 获取已开电子票的invoiceInfo */
export const GetOpenedElectronicInvoiceInfo = (obj) => requests({
    url: '/getOpenedElectronicInvoiceInfo',
    method: 'get',
    params: {
        projName: obj.projName,
        month: obj.month,
        clientName: obj.clientName
    }
})

/* 根据 invoiceInfo 获取账单 */
export const GetWuyeBillsByInvoiceInfo = (invoiceInfo) => requests({
    url: '/getWuyeBillsByInvoiceInfo',
    method: 'get',
    params: {
        invoiceInfo: invoiceInfo
    }
})

/* 获取 park_order */
export const GetParkOrders = (obj,pageSize, currentPage) => requests({
    url: '/getParkOrders',
    method: 'get',
    params: {
        projName: obj.projName,
        firstDay: obj.timeRange[0],
        lastDay: obj.timeRange[1],
        phone: obj.phone,
        pageSize: pageSize,
        currentPage: currentPage
    }
})

/* 关键字查询跟进记录 */
export const GetBusinessFollowUpByKeyword = (obj,unionid,pageSize, currentPage) => requests({
    url: '/getBusinessFollowUpByKeyword',
    method: 'get',
    params: {
        keyword: obj.keyword,
        unionid: unionid,
        pageSize: pageSize,
        currentPage: currentPage
    }
})

/* 车位订单详情 */
export const GetParkOrderDetail = (id) => requests({
    url: '/getParkOrderDetail',
    method: 'get',
    params: {
        id: id
    }
})

/* 通过第三方支付的 orderId 获取 wuye_Bill */
export const GetWuyeBillByOrderId = (orderId) => requests({
    url: '/getWuyeBillByOrderId',
    method: 'get',
    params: {
        orderId: orderId
    }
})

/* 获取 park_user */
export const GetParkUser = (unionid) => requests({
    url: '/getParkUser',
    method: 'get',
    params: {
        unionid: unionid
    }
})

/* 更新 reviewer */
export const UpdateWuyeBillReviewer = (id,unionid) => requests({
    url: '/updateWuyeBillReviewer',
    method: 'post',
    params: {
        id: id,
        unionid: unionid
    }
})

/* 获取开票中的park_order */
export const GetInvoicingParkOrder = (projName) => requests({
    url: '/getInvoicingParkOrder',
    method: 'get',
    params: {
        projName: projName
    }
})

/* 获取已开票的park_order */
export const GetInvoicedParkOrder = (obj,pageSize, currentPage) => requests({
    url: '/getInvoicedParkOrder',
    method: 'get',
    params: {
        projName: obj.projName,
        firstDay: obj.timeRange[0],
        lastDay: obj.timeRange[1],
        pageSize: pageSize,
        currentPage: currentPage
    }
})

/* 获取 park_invoice 需要的开票信息 */
export const GetParkInvoiceOpenInfo = (applicationId) => requests({
    url: '/getParkInvoiceOpenInfo',
    method: 'get',
    params: {
        applicationId: applicationId
    }
})

/* 发送开票完成通知 */
export const SendParkInvoiceMsg = (applicationId) => requests({
    url: '/sendParkInvoiceMsg',
    method: 'post',
    params: {
        applicationId: applicationId
    }
})
