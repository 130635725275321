<template>
    <div>
        <el-menu
                :default-active="moduleName"
                class="el-menu-demo"
                mode="horizontal"
                @select="handleSelect">

            <el-menu-item index="title" class="nav-title">
                <span>步步高·稻谷</span>
            </el-menu-item>

            <el-menu-item index="businessModule">业务管理</el-menu-item>
            <el-menu-item index="assetsModule">资产管理</el-menu-item>
            <el-menu-item index="elecModule" :disabled="!authority">电能耗管理</el-menu-item>
            <!--<el-menu-item index="wuyeModule" :disabled="!authority">稻谷物业</el-menu-item>-->
            <el-menu-item index="propertyModule" :disabled="!authority">物业管理</el-menu-item>
            <el-menu-item index="wuyeMiniProModule" :disabled="!authority">稻谷物业</el-menu-item>
            <el-menu-item index="parkModule" :disabled="!authority">稻谷停车</el-menu-item>

            <el-menu-item v-if="comments > 0" index="commentBtn" style="position: absolute;right: 100px">
                <span style="color: #eb4d4b">新的评论/回复 </span>
                <el-tag type="danger" size="mini" effect="dark">{{comments}}</el-tag>
            </el-menu-item>

            <el-menu-item index="name" class="nav-name">
                <span>{{name}}</span>
            </el-menu-item>

        </el-menu>

        <!-- 未读消息 抽屉-->
        <el-drawer :visible.sync="drawer" size="50%" destroy-on-close :append-to-body="true">
            <template slot="title">
                <el-alert
                        title="未读 评论/回复"
                        type="warning"
                        :closable="false">
                </el-alert>
            </template>
            <UnreadComments></UnreadComments>
        </el-drawer>

    </div>

</template>

<script>
    import {GetEmployeeName, GetUnreadBusinessComments, GetWuyeAuthority} from "../api";
    import UnreadComments from "./business/UnreadComments"

    export default {
        name: 'TopNav',
        components: {UnreadComments},
        data() {
            return {
                authority: true,
                drawer: false,
                unionid: null,
                name: null,
                comments: 0
            }
        },
        props: {
            moduleName: {
                type: String,
                required: true
            }
        },
        watch: {
            drawer: function (val, oldVal) {
                if (!val) {
                    GetUnreadBusinessComments(this.unionid).then((res) => {
                        this.comments = res
                    })
                }
            }
        },
        methods: {
            handleSelect(key) {
                if (key === 'commentBtn') {
                    this.drawer = true
                    this.comments = 0
                }
                /*GetUnreadBusinessComments(this.unionid).then((res)=>{
                    this.comments = res
                })*/
                this.$emit('ChangeModule', key)
            },
        },
        created() {
            this.unionid = sessionStorage.getItem('unionid')

            GetWuyeAuthority(this.unionid).then((res) => {

                if (res === '' || res == null) {
                    this.authority = false
                } else {
                    this.authority = true
                }
            })

            GetEmployeeName(this.unionid).then((res) => {
                this.name = res
            })

            GetUnreadBusinessComments(this.unionid).then((res) => {
                this.comments = res
            })

        }
    }
</script>

<style scoped>
    .nav-title {
        width: 200px;
        /*color: #409EFF!important;*/
        color: #535252 !important;
        padding-left: 30px !important;
        font-size: 18px;
        pointer-events: none;
        font-weight: bold;
    }

    .nav-name {
        /*color: #409EFF!important;*/
        color: #535252 !important;
        /*float: right!important;*/
        position: absolute;
        right: 10px;
        pointer-events: none;
        /*font-size: 18px;*/
        /*font-weight: bold;*/
    }

    .el-menu-demo {
        width: 100%;
    }
</style>
