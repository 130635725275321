<template>
    <div class="tab-container">
        <el-tabs v-model="editableTabsValue"
                 type="card"
                 closable
                 @tab-remove="handleTabsRemove"
                 @tab-click="handleTabsClick">

            <el-tab-pane
                    v-for="(item, index) in editableTabs"
                    :key="item.name"
                    :label="item.title"
                    :name="item.name" style="padding: 2px">

                <component :is="item.content"></component>

            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import HouseManagement from './assert/HouseManagement'
    import TransferSituationManagement from "./assert/TransferSituationManagement";
    import HouseClientManagement from "./assert/HouseClientManagement";
    import OtherClientManagement from "./assert/OtherClientManagement";
    import ElecMeterManagement from "./elec/ElecMeterManagement";
    import ElecRecordManagement from "./elec/ElecRecordManagement";
    import ElecReportManagement from "./elec/ElecReportManagement"
    import ElecBillPlanManagement from "./elec/ElecBillPlanManagement"
    import ElecBillManagement from "./elec/ElecBillManagement"
    import DailyReportManagement from "./business/DailyReportManagement"
    import BusinessManagement from "./business/BusinessManagement"
    import BusinessSearch from "./business/BusinessSearch"
    import BusinessEveryDayCount from "./business/BusinessEveryDayCount"
    import BusinessEveryOneCount from "./business/BusinessEveryOneCount"
    import BusinessCount from "./business/BusinessCount"
    import PublicBusinessInfos from "./business/PublicBusinessInfos"
    import WuyeUserInfo from "./wuye/WuyeUserInfo"
    import WuyeUser from "./wuye/WuyeUser"
    import WuyeElecCertificate from "./wuye/WuyeElecCertificate"
    import WuyeElecPayment from "./wuye/WuyeElecPayment"
    import WuyeElecInvoice from "./wuye/WuyeElecInvoice"
    import WuyeBillManagement from "./property/WuyeBillManagement"
    import WuyeBillStatistics from "./property/WuyeBillStatistics"
    import WuyeClientManagement from "./property/WuyeClientManagement"
    import WuyeElecInvoiceManagement from "./wuye/WuyeElecInvoiceManagement"
    import WyMpUserInfoManagement from "./wuyeMiniPro/WyMpUserInfoManagement"
    import WyMpUserCertificationManagement from "./wuyeMiniPro/WyMpUserCertificationManagement"
    import WyMpPayManagement from "./wuyeMiniPro/WyMpPayManagement"
    import WyMpMsgManagement from "./wuyeMiniPro/WyMpMsgManagement"
    import WyMpInvoiceManagement from "./wuyeMiniPro/WyMpInvoiceManagement"
    import ParkingManagement from "./property/ParkingManagement"
    import InternalElecReportManagement from "./elec/InternalElecReportManagement"
    import WuyeInvoiceOpen from "./property/WuyeInvoiceOpen"
    import WuyeInvoiceView from "./property/WuyeInvoiceView"
    import ParkOrderManagement from "./park/ParkOrderManagement"
    import BusinessKeySearch from "./business/BusinessKeySearch"
    import ParkInvoiceApplicationManagement from "./park/ParkInvoiceApplicationManagement"

    export default {
        name: "Tab",
        data() {
            return {
                /*标签对象,属性数组中依次存放 title componentName moduleName*/
                tabItem: {
                    dailyReport: ['日常汇报', 'DailyReportManagement', 'businessModule'],
                    myBusiness: ['我的商机', 'BusinessManagement', 'businessModule'],
                    clientSearch: ['客户查询', 'BusinessSearch', 'businessModule'],
                    dailyCount: ['每日统计', 'BusinessEveryDayCount', 'businessModule'],
                    workSummary: ['工作汇总', 'BusinessEveryOneCount', 'businessModule'],
                    allBusiness: ['全部商机', 'BusinessCount', 'businessModule'],
                    publicBusiness: ['公共商机', 'PublicBusinessInfos', 'businessModule'],
                    houseManagement: ['房源管理', 'HouseManagement', 'assetsModule'],
                    houseClient: ['房源客户', 'HouseClientManagement', 'assetsModule'],
                    otherClient: ['其他客户', 'OtherClientManagement', 'assetsModule'],
                    transferSituation: ['过户情况', 'TransferSituationManagement', 'assetsModule'],
                    elecMeter: ['表计档案', 'ElecMeterManagement', 'elecModule'],
                    elecRecord: ['抄表记录', 'ElecRecordManagement', 'elecModule'],
                    elecReport: ['电能耗报表', 'ElecReportManagement', 'elecModule'],
                    internalElecReport: ['内部用电报表', 'InternalElecReportManagement', 'elecModule'],
                    elecBillPlan: ['电费方案', 'ElecBillPlanManagement', 'elecModule'],
                    elecBill: ['电费账单', 'ElecBillManagement', 'elecModule'],
                    wuyeUserInfo: ['用户信息', 'WuyeUserInfo', 'wuyeModule'],
                    wuyeUser: ['用户认证', 'WuyeUser', 'wuyeModule'],
                    wuyeElecCertificate: ['线下打款', 'WuyeElecCertificate', 'wuyeModule'],
                    wuyeElecPayment: ['微信支付', 'WuyeElecPayment', 'wuyeModule'],
                    WuyeElecInvoice: ['电费发票', 'WuyeElecInvoiceManagement', 'wuyeModule'],
                    wuyeClientManagement: ['物业客户', 'WuyeClientManagement', 'propertyModule'],
                    wuyeBillManagement: ['物业账单明细', 'WuyeBillManagement', 'propertyModule'],
                    wuyeBillStatistics: ['物业账单统计', 'WuyeBillStatistics', 'propertyModule'],
                    wympUserInfo: ['用户信息', 'WyMpUserInfoManagement', 'wuyeMiniProModule'],
                    wympUserCertification: ['认证管理', 'WyMpUserCertificationManagement', 'wuyeMiniProModule'],
                    wympPayment: ['微信支付', 'WyMpPayManagement', 'wuyeMiniProModule'],
                    wympMsg: ['消息推送', 'WyMpMsgManagement', 'wuyeMiniProModule'],
                    wympInvoice: ['电子发票', 'WyMpInvoiceManagement', 'wuyeMiniProModule'],
                    parkingManagement: ['车位资产', 'ParkingManagement', 'propertyModule'],
                    wuyeInvoiceOpen: ['数电发票-开票', 'WuyeInvoiceOpen', 'propertyModule'],
                    wuyeInvoiceView: ['数电发票-已开发票', 'WuyeInvoiceView', 'propertyModule'],
                    parkOrderManagement: ['车位订单','ParkOrderManagement','parkModule'],
                    businessKeySearch: ['关键字搜索','BusinessKeySearch','businessModule'],
                    parkInvoiceApplicationManagement: ['发票申请','ParkInvoiceApplicationManagement','parkModule']
                },
                editableTabsValue: 'houseManagement',
                editableTabs: [
                    {
                        name: 'houseManagement',
                        title: '房源管理',
                        content: 'HouseManagement'
                    }
                ]
            }
        },
        props: {
            newTab: {
                type: String,
                required: false
            }
        },
        watch: {
            newTab: function (newVal) {

                if (newVal === 'empty') return

                var tab = this.editableTabs.find(function (editableTab) {
                    return editableTab.name === newVal
                })

                if (tab != null) {
                    /*标签已存在*/
                } else {
                    /*新增标签*/
                    this.editableTabs.push({
                        name: newVal,
                        title: this.tabItem[newVal][0],
                        content: this.tabItem[newVal][1]
                    })

                }

                this.editableTabsValue = newVal
            }
        },
        methods: {
            handleTabsRemove(tabName) {

                //获取删除 tab 下标
                const index = this.editableTabs.findIndex(item => item.name === tabName);

                //根据 index 从数组中删除
                this.editableTabs.splice(index, 1)

                //如果当前处于要删除的 tab 下，修改 editableTabsValue
                if (this.editableTabsValue === tabName && this.editableTabs.length > 0) {
                    this.editableTabsValue = this.editableTabs[index > 0 ? index - 1 : index].name
                }

                this.$emit('ChangeMenu', this.editableTabs.length > 0 ? this.editableTabsValue : 'empty')

            },
            handleTabsClick(tab) {
                this.$emit('ChangeModule', this.tabItem[this.editableTabs[tab.index].name][2])
                this.$emit('ChangeMenu', this.editableTabs[tab.index].name)
            }
        },
        components: {
            HouseManagement,
            TransferSituationManagement,
            HouseClientManagement,
            OtherClientManagement,
            ElecMeterManagement,
            ElecRecordManagement,
            ElecReportManagement,
            ElecBillPlanManagement,
            ElecBillManagement,
            DailyReportManagement,
            BusinessManagement,
            BusinessSearch,
            BusinessEveryDayCount,
            BusinessEveryOneCount,
            BusinessCount,
            PublicBusinessInfos,
            WuyeUserInfo,
            WuyeUser,
            WuyeElecCertificate,
            WuyeElecPayment,
            WuyeElecInvoice,
            WuyeClientManagement,
            WuyeBillManagement,
            WuyeElecInvoiceManagement,
            WuyeBillStatistics,
            WyMpUserInfoManagement,
            WyMpUserCertificationManagement,
            WyMpPayManagement,
            WyMpMsgManagement,
            WyMpInvoiceManagement,
            ParkingManagement,
            InternalElecReportManagement,
            WuyeInvoiceOpen,
            WuyeInvoiceView,
            ParkOrderManagement,
            BusinessKeySearch,
            ParkInvoiceApplicationManagement
        }
    }
</script>

<style scoped>

    .tab-container {
        height: -webkit-calc(100vh - 100px);
        background-color: white;
        padding: 10px;
        border-radius: 2px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
    }

</style>
